import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import HeaderIntroBlock from '../../components/Blocks/HeaderIntroBlock'
import SectionBoxReady from '../../components/outcomes/SectionBoxReady'
import { FaqAccordionBlock } from '../../components/Blocks/AccordionBlock'
import BreadcrumbSection from '../../components/breadcrumb'
import PopupSchedule from '../../components/popupSchedule/PopupSchedule'

const Analytics = ({ location }) => {
     const canonical = process.env.GATSBY_SITE_URL + location.pathname

     const headerIntroContent = {
          // subtitle: 'subtitle demo',
          title: 'Google Analytics Alternative',
          // description: 'Replace or augment your use of Google Analytics with an alternative that is more powerful and privacy compliant.',
          substring: 'Replace or augment your use of Google Analytics with an alternative that is more powerful and privacy compliant.',
     }
     const headerIntroCard = {
          description: `<span>For years, Google has taken your data in exchange for "free" use of their platform. But nothing is "free" and, the insights you're missing by only leveraging Google Analytics will amaze you!</span>`,
          fName: 'Zachary W. Randall',
          fPosition: 'Founder & CEO',
          imageURL: '/images/hero-image.png',
     }

     const data = [
          {
               title: 'Cookieless',
               content: 'ListenLayer can operate 100% free of cookies, while still providing the benefits without the risks. ',
          },
          {
               title: 'Compliant',
               content:
                    'ListenLayer is built to allow compliance with GDPR, CCPA, other privacy laws, and even HIPAA. Use our prebuilt rules to govern your compliance strategy and even replace other CMPs to reduce risk.',
          },
          {
               title: 'Secure',
               content:
                    'Security is a top priority. We care about protecting your data so you can use it the way you need.  ListenLayer is currently pursuing SOC2 Type 2 certification. ',
          },
          {
               title: 'Built for B2B',
               content:
                    'B2B marketers gain new insight into their marketing activity. Reveal competitors and target accounts as they browse your website and connect external data for revenue and pipeline attribution.',
          },
          {
               title: 'Built for B2C',
               content:
                    'Whether your focus is eCommerce or lead generation (or both) ListenLayer can automate the collection of data and generate meaningful insights.',
          },
          {
               title: 'Built for Healthcare',
               content:
                    "ListenLayer's approach to HIPAA compliance allows you to centralize marketing analytics and control how data is distributed to platforms like Facebook and Google. ",
          },
          {
               title: 'Prebuilt Reports',
               content:
                    'A large set of prebuilt reports allow you to quickly analyze user behavior, understand traffic sources, and gain insights from your marketing - without building everything from scratch. ',
          },
          {
               title: 'Identification & Attribution',
               content:
                    'Identify real people, companies and competitors alongside your analytics and conversion data in order to power full attribution. ',
          },
          {
               title: 'Extensible',
               content:
                    "Connect external sources to extend ListenLayer's power alongside your advertising and CRM data. Now you will finally learn the truth!",
          },
          {
               title: 'No Sampling',
               content:
                    "ListenLayer reports on real data from your marketing and website.  We don't sample data simply to run reports a little faster - we show you the truth (and it's still really fast)!",
          },
          {
               title: 'No Data Limits',
               content: 'Register custom dimensions and metrics without limitations.  Build custom reports that are not limited to the last 90 day!',
          },
          {
               title: 'Easy to Customize',
               content:
                    'ListenLayer centralizes and automates your entire marketing data strategy. Learn everything you need to know without programming and managing custom event data with limits. ',
          },
     ]

     const headline = 'Frequently Asked Questions'

     const faqs = [
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>Yes! Google has taught us for years that \"your data must be anonymous...\" But, that's because it's not your data, it's their data! As long as we respect privacy laws in various regions (which ListenLayer's regional consent rules do), then identifying your own leads and customers alongside your marketing data is perfectly okay!</p>\n",
                    faqQuestion: 'Are we allowed to identify people in our marketing data?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>At the end of the day, people buy from you.  By identifying your leads and customers in your web analytics data, you can finally tie marketing activity to revenue! ListenLayer allows you to connect outside data sources to your marketing data, giving you a full view of attribution. </p>\n',
                    faqQuestion: 'What is the benefit of identifying people?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         '<p>Yes. You can use ListenLayer and gain all the benefits of the platform while not enabling the Reveal People feature.</p>\n',
                    faqQuestion: 'Is it optional to identify people in my analytics data?',
               },
               type: 'WpCptFaq',
          },
          {
               __typename: 'WpCptFaq',
               cfFaq: {
                    faqAnswer:
                         "<p>ListenLayer's Reveal Companies feature does require an additional subscription. Some of our higher plans include a certain number of monthly matches, and additional matching can be purchased.</p>\n",
                    faqQuestion: 'What is the cost of identifying companies and people?',
               },
               type: 'WpCptFaq',
          },
     ]

     const buttonText = ''

     const pageName = 'Gain Insights'
     const breadcrumbItems = [
          {
               name: 'Platform',
               url: `${'platform'}`,
          },
          {
               name: `${pageName}`,
          },
     ]
     const [showSchedule, setShowSchedule] = useState(false)

     useEffect(() => {
          if (showSchedule) {
               document.querySelector('html').setAttribute('style', 'overflow:hidden')
          } else {
               document.querySelector('html').setAttribute('style', 'overflow:initial')
          }
     }, [showSchedule])

     useEffect(() => {
          if (window.location.href.includes('view-demo=1')) {
               setShowSchedule(true)
          }
     }, [])

     return (
          <Layout>
               <SEO classBody="custom-centralized-marketing" canonical={canonical} />
               <PopupSchedule showSchedule={showSchedule} setShowSchedule={setShowSchedule} />
               <BreadcrumbSection breadcrumbItems={breadcrumbItems}></BreadcrumbSection>
               <HeaderIntroBlock headerIntroContent={headerIntroContent} headerIntroCard={headerIntroCard}></HeaderIntroBlock>
               <section>
                    <div class="container">
                         <div class="headline-bodytext-wrapper bodytext-width-large">
                              <h3 class="text-center">More Than Behavioral Analytics</h3>
                              <div class="bodyCopy text-center ">
                                   <p>
                                        Think of ListenLayer as "Google Analytics" with superpowers. Imagine a centralized view of your marketing
                                        data, a single source of truth that allows you to identify real people, companies, competitors, and cohorts
                                        that you care about. Then tie in external data for revenue and pipeline attribution, and throw in powerful
                                        insights generated through Machine Learning and Generative AI.
                                   </p>
                                   <p>
                                        Layer on Consent & Privacy management that is centralized to reduce risk (and cost) from using third party
                                        tools. Finally, realize that this data is yours! It doesn't belong to a large company controlling how you gain
                                        insights and using it for their own benefit.{' '}
                                   </p>
                              </div>
                         </div>
                    </div>
               </section>

               <section className="platform-step-card mt-4">
                    <div className="container">
                         <div className="box-card-step">
                              <div className="row">
                                   {data &&
                                        data.map(item => (
                                             <div className="col-md-6 col-lg-4 mb-4">
                                                  <div className="card-step h-100">
                                                       <div className="card-body mb-0">
                                                            <h3>{item.title}</h3>
                                                            <p className="card-desc">{item.content}</p>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                              </div>
                         </div>
                    </div>
               </section>

               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
               />

               <section className="enhance-faq">
                    <div className="container">
                         <FaqAccordionBlock faqs={faqs} buttonText={buttonText} headline={headline} />
                    </div>
               </section>

               <SectionBoxReady
                    title={'Ready to Dive In?'}
                    content={'See how easy (and powerful) ListenLayer is by opening an account today or requesting a demo.'}
                    style={'30px'}
                    className={'custom-content'}
               />
          </Layout>
     )
}

export default Analytics
